export const words = [
  "Konnichiwa",
  "Bonjour",
  "Hallo",
  "Salve",
  "नमस्ते ",
  "Hello",
  "Anyeonghaseyo",
  "Hola",
  "Herete",
  "Olá",
  "Herete",
  "Konnichiwa",
  "Bonjour",
  "Hallo",
  "Salve",
  "नमस्ते ",
  "Hello",
  "Anyeonghaseyo",
  "Hola",
  "Herete",
  "Olá",
  "Herete",
  "Konnichiwa",
  "Bonjour",
  "Hallo",
  "Salve",
  "नमस्ते ",
  "Hello",
  "Anyeonghaseyo",
  "Hola",
  "Herete",
  "Olá",
  "Herete",
  "Konnichiwa",
  "Bonjour",
  "Hallo",
  "Salve",
  "नमस्ते ",
  "Hello",
  "Anyeonghaseyo",
  "Hola",
  "Herete",
  "Olá",
  "Herete",
  "Konnichiwa",
  "Bonjour",
  "Hallo",
  "Salve",
  "नमस्ते ",
  "Hello",
  "Anyeonghaseyo",
  "Hola",
  "Herete",
  "Olá",
  "Herete",
];
